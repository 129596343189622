// Types
import { hasInputCurrencyErrorsPropsType } from './types/has-input-currency-errors.type';
// Utils
import {
  getControllerMinAmount,
  isMinAmount,
} from 'containers/notifications/views/notifications-settings/components/list/utils/notifications-settings-list.utils';

const hasInputCurrencyErrors = ({
  notificationsSettings,
}: hasInputCurrencyErrorsPropsType): boolean | undefined => {
  return notificationsSettings?.categories?.some(category => {
    return category?.notifications?.some(notification => {
      return notification?.controllers?.some(controller => {
        const minAmount = getControllerMinAmount({ notificationSetting: notification });
        const unformattedValue = Number(controller.amount);

        return isMinAmount({ amount: unformattedValue, minAmount }) && notification.enabled;
      });
    });
  });
};

export { hasInputCurrencyErrors };
