// Types
import {
  NotFoundBuilderHandlersType,
  NotFoundMethodsHandlersType,
} from './types/not-found.handlers.type';

const goBackFrom404ViewButtonClickHandler = ({
  handleGoBackFrom404ViewButtonClickTracking,
  onCancelHybridFlow,
}: NotFoundBuilderHandlersType): void => {
  handleGoBackFrom404ViewButtonClickTracking();
  onCancelHybridFlow();
};

const NotFoundHandlers = (props: NotFoundBuilderHandlersType): NotFoundMethodsHandlersType => ({
  handleGoBackFrom404ViewButtonClick: () => goBackFrom404ViewButtonClickHandler(props),
});

export { NotFoundHandlers };
