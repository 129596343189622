// Vendors
import styled from 'styled-components';
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const LoginGapsViewStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};
  text-align: left;
  width: 100%;
`;

export const LoginGapsContentViewStyled = styled(LoginGapsViewStyled)`
  max-width: ${convertPxsToRems({ pixels: 300, base: 14 })};
`;

export const LoginGapsInputViewStyled = styled(LoginGapsViewStyled)`
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
