// Types
import { LoginGapsBuilderUiHandlersType } from './types/login-gaps-builder.ui.handlers.type';
import { LoginGapsMethodBuilderUiHandlersType } from './types/login-gaps-method-builder.ui.handlers.type';
import { LoginGapsBuilderReturnUiHandlersType } from './types/login-gaps-builder-return.ui.handlers.type';
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const forgetPasswordLinkClickHandler = ({
  handleForgetPasswordLinkClickTracking,
  navigate,
}: Pick<
  LoginGapsBuilderUiHandlersType,
  'handleForgetPasswordLinkClickTracking' | 'navigate'
>): any => {
  handleForgetPasswordLinkClickTracking();
  navigate(generateAbsoluteRoute(RoutesEnumeration.RECOVERY_KEY));
};

const saveUserPasswordInputEventHandler = ({
  newPassword,
  setError,
  setPassword,
}: LoginGapsMethodBuilderUiHandlersType): any => {
  setError(false);
  setPassword(newPassword);
};

const LoginGapsUiHandlers = ({
  handleForgetPasswordLinkClickTracking,
  navigate,
  setPassword,
  setError,
}: LoginGapsBuilderUiHandlersType): LoginGapsBuilderReturnUiHandlersType => ({
  handleForgetPasswordLinkClick: () =>
    forgetPasswordLinkClickHandler({ handleForgetPasswordLinkClickTracking, navigate }),
  handleSaveUserPasswordInputEvent: (newPassword: string) =>
    saveUserPasswordInputEventHandler({
      newPassword,
      setError,
      setPassword,
    }),
});

export default LoginGapsUiHandlers;
