// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import {
  NOTIFICATIONS_ROUTE_PROPS,
  NOTIFICATIONS_SETTINGS_ROUTE_PROPS,
} from './constants/notifications-router.constants';
import FiltersComponent from 'components/filters/filters-component';
// Views
const NotificationsLandingView = lazy(
  () => import('containers/notifications/views/landing/notifications-landing.view')
);
const NotificationsSettingsView = lazy(
  () => import('containers/notifications/views/notifications-settings/notifications-settings.view')
);

const NotificationsRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route
      {...NOTIFICATIONS_ROUTE_PROPS}
      element={
        <FiltersComponent filterId="documentation">
          <NotificationsLandingView />
        </FiltersComponent>
      }
    />
    <Route {...NOTIFICATIONS_SETTINGS_ROUTE_PROPS} element={<NotificationsSettingsView />} />
  </Routes>
);

export default NotificationsRouterComponent;
