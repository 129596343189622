// Constants
import constants from 'containers/login/views/gaps/handlers/constants/login-gaps.handlers.constants';
import { HYBRID_FLOWS } from './constants/orchestrator.handlers.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Handlers
import { getLoginRedirectUrl } from 'containers/login/views/gaps/handlers/utils/login-gaps-api.handlers.utils';
// Types
import { OrchestratorBuilderReturnHandlersType } from './types/orchestrator-builder-return.handlers.type';
import { OrchestratorBuilderHandlersType } from './types/orchestrator-builder.handlers.type';
// Utils
import {
  generateAbsoluteRoute,
  getLastPathNameUrlItem,
  getPathnameByLocation,
} from 'utils/app-routing.utils';
import { publicSiteIsDemandingChat } from 'utils/public-interactions/public-interactions.utils';

const redirectToOnboarding = (): void => {
  localStorage.setItem(constants.LOGIN_FROM_PRIVATE, String(true));
  window.location.replace(generateAbsoluteRoute(getLoginRedirectUrl(true)));
};

const resetPageLoaderErrorEventHandler = (
  setError: OrchestratorBuilderHandlersType['setError']
): void => {
  setError(false);
};

const changePrevPathLocation = (pathName: OrchestratorBuilderHandlersType['pathName']): void =>
  localStorage.setItem('prevPath', getPathnameByLocation(pathName) || RoutesEnumeration.DASHBOARD);

const updateHybridFlowsEventsHandler = (
  onHybridFlow: OrchestratorBuilderHandlersType['onHybridFlow']
): void => {
  const currentPath = getLastPathNameUrlItem();

  HYBRID_FLOWS.includes(currentPath as RoutesEnumeration) && onHybridFlow(currentPath);
};

const watchInteractionsPublicSiteFlagsHandler = (
  navigate: OrchestratorBuilderHandlersType['navigate']
): void => {
  publicSiteIsDemandingChat() && navigate(generateAbsoluteRoute(RoutesEnumeration.SUPPORT));
};

const OrchestratorHandlers = ({
  pathName,
  navigate,
  onHybridFlow,
  setError,
}: OrchestratorBuilderHandlersType): OrchestratorBuilderReturnHandlersType => ({
  handleChangePrevPathLocation: () => changePrevPathLocation(pathName),
  handleOnboardingRedirect: () => redirectToOnboarding(),
  handleResetPageLoaderErrorEvent: () => resetPageLoaderErrorEventHandler(setError),
  handleUpdateHybridFlowsEvents: () => updateHybridFlowsEventsHandler(onHybridFlow),
  handleWatchInteractionsPublicSiteFlags: () => watchInteractionsPublicSiteFlagsHandler(navigate),
});

export { OrchestratorHandlers };
