// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ButtonComponent, ParagraphComponent, TitleComponent } from '@openbank/cb-ui-commons';
import LoginLinksComponent from '../../components/links/login-links.component';
// Constants
import constants from './constants/login-error.constants';
// Custom Hooks
import LoginErrorHook from './hooks/login-error.hook';
// Styles
import { LoginFormContentChildrenContainerStyled } from '../../login.container.styled';
import { LoginErrorViewStyled } from './login-error.view.styled';
// Translations
import translations from './translations/login-error.translations';

const LoginErrorView = (): React.ReactElement => {
  const { handleRetryAgainButtonClick } = LoginErrorHook();

  return (
    <LoginErrorViewStyled>
      <TitleComponent {...constants.LOGIN_ERROR_TITLE_PROPS}>
        <FormattedMessageComponent id={translations.TITLE} />
      </TitleComponent>
      <LoginFormContentChildrenContainerStyled>
        <ParagraphComponent {...constants.LOGIN_ERROR_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={translations.DESCRIPTION} />
        </ParagraphComponent>
        <ButtonComponent
          {...constants.LOGIN_ERROR_BUTTON_PROPS}
          onClick={handleRetryAgainButtonClick}
        >
          <FormattedMessageComponent id={translations.BUTTON} />
        </ButtonComponent>
      </LoginFormContentChildrenContainerStyled>
      <LoginLinksComponent {...constants.LOGIN_ERROR_LINKS_PROPS} />
    </LoginErrorViewStyled>
  );
};

export default LoginErrorView;
