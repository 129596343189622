// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  ButtonComponent,
  DividerComponent,
  LinkComponent,
  PasswordModuleComponent,
} from '@openbank/cb-ui-commons';
import LoginGapsTitleComponent from './components/title/login-gaps-title.component';
import LoginLinksComponent from '../../components/links/login-links.component';
import LoginMessageComponent from '../../components/message/login-message.component';
// Constants
import constants from './constants/login-email.constants';
// Custom Hooks
import LoginGapsHook from 'containers/login/views/gaps/hooks/login-gaps.hook';
// Styles
import { LoginFormContentChildrenContainerStyled } from '../../login.container.styled';
import {
  LoginGapsInputViewStyled,
  LoginGapsViewStyled,
} from 'containers/login/views/gaps/login-gaps.view.styled';
// Translations
import translations from './translations/login-gaps.translations';
// Utilities
import { isContinueButtonDisabled } from './utils/login-gaps.utils';

const LoginGapsView = (): React.ReactElement => {
  const {
    fetching,
    formatMessage,
    error,
    handleForgetPasswordLinkClick,
    handleContinuePasswordGapsButtonClick,
    handlePasswordPositionInputClickTracking,
    handleSaveUserPasswordInputEvent,
    password,
  } = LoginGapsHook();

  return (
    <LoginGapsViewStyled>
      <LoginGapsTitleComponent />
      <LoginFormContentChildrenContainerStyled>
        <LoginGapsInputViewStyled>
          <PasswordModuleComponent
            {...constants.LOGIN_GAPS_INPUT_PROPS}
            errorMessage={error}
            disabled={fetching}
            onChange={handleSaveUserPasswordInputEvent}
            onClick={handlePasswordPositionInputClickTracking}
            title={formatMessage({ id: translations.INPUT_LABEL })}
            value={password}
          />
          <LinkComponent
            {...constants.LOGIN_GAPS_LINK_PROPS}
            disabled={fetching}
            onClick={handleForgetPasswordLinkClick}
          >
            <FormattedMessageComponent id={translations.FORGET_PASSWORD} />
          </LinkComponent>
        </LoginGapsInputViewStyled>
        <LoginMessageComponent {...{ ...constants.LOGIN_GAPS_ERROR_MESSAGE, error }} />
        <ButtonComponent
          {...constants.LOGIN_EMAIL_BUTTON_PROPS}
          disabled={isContinueButtonDisabled({ fetching, password, error })}
          loading={fetching}
          onClick={handleContinuePasswordGapsButtonClick}
        >
          <FormattedMessageComponent id={translations.BUTTON} />
        </ButtonComponent>
      </LoginFormContentChildrenContainerStyled>
      <DividerComponent {...constants.LOGIN_EMAIL_DIVIDER_PROPS} />
      <LoginLinksComponent {...{ ...constants.LOGIN_GAPS_LINKS_PROPS }} />
    </LoginGapsViewStyled>
  );
};

export default LoginGapsView;
