// Vendors
import styled from 'styled-components';
import { LoginGapsViewStyled } from '../gaps/login-gaps.view.styled';

export const LoginEmailViewStyled = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};
  text-align: center;
  width: 100%;
`;

export const LoginEmailInputMessageViewStyled = styled(LoginEmailViewStyled)`
  gap: ${({ theme }) => theme.spaces.spacingM};
  text-align: left;
`;

export const LoginGapsInputViewStyled = styled(LoginGapsViewStyled)`
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const LoginMessagesViewStyled = styled(LoginGapsViewStyled)`
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
