// Services
import { getPasswordPositionsService, loginUserService } from '../services/login-gaps.services';
// Types
import { LoginGapsApiLoginBuilderType } from '../types/login-gaps-api-login-builder.type';
import { LoginGapsApiLoginReturnType } from '../types/login-gaps-api-login-return.type';
// Utilities
import {
  isValidPositionsContract,
  isValidSessionContract,
  isOnboardingIncomplete,
  isAccessKeyExpired 
} from './utils/login-gaps.resolvers.utils';

const getPasswordPositionsResolver = async (
  username: string
): Promise<[string[]] | [null, true]> => {
  const [response] = await getPasswordPositionsService(username);

  return isValidPositionsContract(response) ? [response.positions] : [null, true];
};

const loginUserResolver = async (
  params: LoginGapsApiLoginBuilderType
): Promise<[LoginGapsApiLoginReturnType] | [null, true]> => {
  const [response, { headers }] = await loginUserService(params);

  const loginGapsApiResponse = {
    ...response,
    isOnboardingIncomplete: isOnboardingIncomplete(headers),
    accessKeyExpired: isAccessKeyExpired(headers)
  };

  return isValidSessionContract(loginGapsApiResponse)
    ? [loginGapsApiResponse as LoginGapsApiLoginReturnType]
    : [null, true];
};

export { getPasswordPositionsResolver, loginUserResolver };
