// Enumerations
import { LoginViewsEnumeration } from 'containers/login/enumerations/login-views.enumeration';
// Types
import { LoginGapsTitleBuilderHandlersType } from './types/login-gaps-title-builder.handlers.type';
import { LoginGapsTitleBuilderReturnHandlersType } from './types/login-gaps-title-builder-return.handlers.type';

const editUsernameAssetClickHandler = ({
  email,
  handleEditUsernameAssetClickTracking,
  setEmail,
  setLoginView,
  setUserAlias,
}: LoginGapsTitleBuilderHandlersType): any => {
  handleEditUsernameAssetClickTracking();
  setLoginView(LoginViewsEnumeration.EMAIL);
  setEmail(email);
  setUserAlias('');
};

const LoginGapsTitleHandlers = (
  props: LoginGapsTitleBuilderHandlersType
): LoginGapsTitleBuilderReturnHandlersType => ({
  handleEditUsernameAssetClick: () => editUsernameAssetClickHandler(props),
});

export default LoginGapsTitleHandlers;
