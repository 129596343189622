// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/login-gaps.services.constants';
// Types
import { SssLoginPasswordPositionsType } from 'types/sss/login/sss-login-password-positions.type';
import { LoginGapsApiLoginBuilderType } from 'containers/login/views/gaps/types/login-gaps-api-login-builder.type';
import { LoginGapsApiLoginReturnType } from '../types/login-gaps-api-login-return.type';

const getPasswordPositionsService = async (
  username: string
): Promise<[SssLoginPasswordPositionsType | Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...constants.PASSWORD_POSITIONS_SERVICE_CONFIGURATION,
    params: { ...constants.PASSWORD_POSITIONS_SERVICE_PARAMS_CONFIGURATION, username },
  });

const loginUserService = async (
  params: LoginGapsApiLoginBuilderType
): Promise<
  [Omit<LoginGapsApiLoginReturnType, 'isOnboardingIncomplete'> | Record<string, any>, Response]
> =>
  apiUtilsCb.post({
    ...constants.LOGIN_SERVICE_CONFIGURATION,
    params: { ...constants.LOGIN_SERVICE_PARAMS_CONFIGURATION, ...params },
  });

export { getPasswordPositionsService, loginUserService };
