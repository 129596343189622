const DOMAIN = 'cobranded.services.userSettings.login.clLogin.enterEmail.';

export default {
  ABOUT_PASSWORDS: `${DOMAIN}aboutYourPasswords`,
  BUTTON: `${DOMAIN}continueButton`,
  CHECKBOX_LABEL: `${DOMAIN}clCheckbox.label`,
  ERROR_INPUT: `${DOMAIN}cbMessage.invalidEmailDescription`,
  INPUT_LABEL: `${DOMAIN}clInput.label`,
  LINK_1: `${DOMAIN}link1`,
  LINK_2: `${DOMAIN}link2`,
  LINK_3: `${DOMAIN}link3`,
  PLACEHOLDER: `${DOMAIN}clInput.placeholder`,
  REQUEST_ACCESS: `${DOMAIN}requestYourCardAmazon`,
  SECURITY_ADVICE: `${DOMAIN}securityAdvice`,
  TITLE: `${DOMAIN}title`,
  VERIFY_ACCOUNT: `${DOMAIN}verifyMyAccount`,
  FORGET_PASSWORD: `${DOMAIN}forgotPasswordLink`,
  PASSWORD_POSITIONS: `${DOMAIN}passwordPositions`,
};
