// Constants
import constants from './constants/login-email.utils.constants';
// Types
import { LoginGapsUtilsType } from './types/login-gaps.utils.type';

const isValidPasswordPositions = (passwordPositions: string): boolean =>
  passwordPositions?.length === constants.PASSWORD_MAX_LENGTH;

const isContinueButtonDisabled = ({ fetching, password, error }: LoginGapsUtilsType): boolean =>
  Boolean(fetching || error || !isValidPasswordPositions(password));

export { isContinueButtonDisabled, isValidPasswordPositions };
