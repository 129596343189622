// Cosntants
import {
  ORCHESTRATOR_HEADER_AVATAR_PROPS,
  ORCHESTRATOR_HEADER_NAV_ITEMS,
  ORCHESTRATOR_HEADER_MAIN_ROUTE,
} from './orchestrator-header.constants.common';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import sectionsTranslations from 'translations/sections.translations';
import {
  MODAL_BUTTON,
  MODAL_LINK,
  MODAL_TITLE,
} from '../translations/orchestrator-header.translations';

const ORCHESTRATOR_HEADER_USER_NAV_ITEMS = [
  {
    icon: 'user',
    label: sectionsTranslations[RoutesEnumeration.PERSONAL_INFORMATION],
    href: RoutesEnumeration.PERSONAL_INFORMATION,
  },
  {
    icon: 'document',
    label: sectionsTranslations[RoutesEnumeration.DOCUMENTATION],
    href: RoutesEnumeration.DOCUMENTATION,
  },
  {
    icon: 'giving',
    label: sectionsTranslations[RoutesEnumeration.SUPPORT],
    href: RoutesEnumeration.SUPPORT,
  },
  {
    icon: 'security',
    label: sectionsTranslations[RoutesEnumeration.SECURITY],
    href: RoutesEnumeration.SECURITY,
  },
  {
    icon: 'IBAN',
    label: sectionsTranslations[RoutesEnumeration.IBAN_AND_BILLING],
    href: RoutesEnumeration.IBAN_AND_BILLING,
  },
];

const ORCHESTRATOR_LOGOUT_MODAL_PROPS = {
  assetId: 'Attention_Alternative',
  buttonText: MODAL_BUTTON,
  title: MODAL_TITLE,
  link: MODAL_LINK,
};

const ALL_NOTIFICATIONS_TRACKING = {
  eventAction: 'clickLink',
  eventLabel: 'viewAllNotificationsLink',
};

const ORCHESTRATOR_SIDE_BAR_LINK_PROPS = {
  size: 'small',
  closeOnClick: true,
};

export {
  ALL_NOTIFICATIONS_TRACKING,
  ORCHESTRATOR_HEADER_AVATAR_PROPS,
  ORCHESTRATOR_HEADER_NAV_ITEMS,
  ORCHESTRATOR_HEADER_USER_NAV_ITEMS,
  ORCHESTRATOR_LOGOUT_MODAL_PROPS,
  ORCHESTRATOR_SIDE_BAR_LINK_PROPS,
  ORCHESTRATOR_HEADER_MAIN_ROUTE,
};
