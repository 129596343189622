// Enums
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';
import translations from '../translations/notifications-landing.translations';

export const LABELS_LINK_CARD_TITLE_PROPS = {
  tag: 'h4',
  testId: 'notificationsLandingTitle',
};

export const CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS = {
  titleBold: false,
  type: SectionDividerTypesEnumeration.INFORMATIVE,
};

export const SETTINGS_LINK_PROPS = {
  testId: 'notificationsSettingsLink',
  assetConfiguration: {
    id: 'symbols_settings_filled',
  },
  disabled: false,
  iconOnLeft: true,
  label: translations.SETTINGS,
  underline: false,
};

export const FILTERS_LINK_PROPS = {
  testId: 'notificationsFiltersLink',
  assetConfiguration: {
    id: 'filter',
  },
  iconOnLeft: true,
  label: translations.FILTERS,
  underline: false,
};

export const ERROR_NOT_FOUNT_PROPS = {
  assetConfiguration: {
    id: 'Empty_No_Result',
    sizes: { height: 110, width: 110 },
    type: 'illustration',
  },
};
export const CHIPS_FILTERS_PROPS = {
  id: 'date-range',
  assetId: 'navigation_arrow',
};
