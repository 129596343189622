// Vendors
import { KeyboardEvent } from 'react';
// Enumerations
import { LoginViewsEnumeration } from 'containers/login/enumerations/login-views.enumeration';
import { DocumentEventsEnumeration, StorageLocalEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { validateEmailFormatResolver } from '../resolvers/login-email.resolvers';
// Types
import { LoginEmailBuilderReturnHandlersType } from './types/login-email-builder-return.handlers.type';
import { LoginEmailBuilderHandlersType } from './types/login-email-builder.handlers.type';
import { LoginEmailContinueBuilderHandlersType } from './types/login-email-continue-builder.handlers.type';
import { LoginEmailRememberBuilderHandlersType } from './types/login-email-remember-builder.handlers.type';
// Utilities
import { isValidEmail } from 'utils/email.utils';
import { isEnterKeyCode } from './utils/login-email.handlers.utils';

let CALLBACK;

const updateRememberMe =
  (): any =>
  (prevRememberMe: boolean): boolean =>
    !prevRememberMe;

const changeRememberMeCheckBoxEvent = ({
  handleRememberMeChangeCheckboxTracking,
  rememberMe,
  setRememberMe,
}: Pick<
  LoginEmailBuilderHandlersType,
  'handleRememberMeChangeCheckboxTracking' | 'rememberMe' | 'setRememberMe'
>): any => {
  handleRememberMeChangeCheckboxTracking(rememberMe);
  setRememberMe(updateRememberMe());
};

const continuePasswordGapsClick = async ({
  email,
  handleContinuePasswordGapsButtonClickTracking,
  setError,
  setFetching,
  setLoginView,
  setUserAlias,
}: LoginEmailContinueBuilderHandlersType): Promise<void> => {
  handleContinuePasswordGapsButtonClickTracking();
  setFetching(true);
  document.removeEventListener(DocumentEventsEnumeration.KEY_DOWN, CALLBACK);

  const [, error] = await validateEmailFormatResolver(email);

  if (error) {
    setFetching(false);
    setError(true);
  } else {
    if (email !== localStorage.getItem(StorageLocalEnum.USERNAME)) {
      setUserAlias('');
      localStorage.removeItem(StorageLocalEnum.USER_ALIAS);
    }
    setLoginView(LoginViewsEnumeration.GAPS);
  }
};

const checkEnterKeyCodeAndContinue = ({ code, props }: any): any =>
  isEnterKeyCode(code) && isValidEmail(props?.email) && continuePasswordGapsClick(props);

const handleSetupKeyBoardClickForNext = (props: any): void => {
  CALLBACK = ({ code }: KeyboardEvent) => checkEnterKeyCodeAndContinue({ code, props });

  document.addEventListener(DocumentEventsEnumeration.KEY_DOWN, CALLBACK);
};

const saveLoginEmailInputEvent = ({
  handleContinuePasswordGapsButtonClickTracking,
  newEmail,
  setEmail,
  setError,
  setFetching,
  setLoginView,
  setUserAlias,
}): any => {
  setEmail(newEmail);
  setError(false);
  handleSetupKeyBoardClickForNext({
    email: newEmail,
    handleContinuePasswordGapsButtonClickTracking,
    setError,
    setFetching,
    setLoginView,
    setUserAlias,
  });
};

const validateLoginEmailInputEvent = ({
  email,
  handleEmailInputErrorStateTracking,
  setError,
}: Pick<
  LoginEmailBuilderHandlersType,
  'email' | 'handleEmailInputErrorStateTracking' | 'setError'
>): any => {
  if (email && !isValidEmail(email)) {
    setError(true);
    handleEmailInputErrorStateTracking();
  }
};

const loginRememberedUserEvent = ({
  autoLogin,
  email,
  handleContinuePasswordGapsButtonClickTracking,
  handleEnterLoginEmailViewTracking,
  setAutoLogin,
  setError,
  setFetching,
  setLoginView,
  setUserAlias,
}: LoginEmailRememberBuilderHandlersType): any => {
  handleEnterLoginEmailViewTracking();

  if (autoLogin && email) {
    setAutoLogin(false);
    continuePasswordGapsClick({
      email,
      handleContinuePasswordGapsButtonClickTracking,
      setError,
      setFetching,
      setLoginView,
      setUserAlias,
    });
  }
};

const LoginEmailHandlers = ({
  autoLogin,
  email,
  handleContinuePasswordGapsButtonClickTracking,
  handleEmailInputErrorStateTracking,
  handleEnterLoginEmailViewTracking,
  handleRememberMeChangeCheckboxTracking,
  rememberMe,
  setAutoLogin,
  setEmail,
  setError,
  setFetching,
  setLoginView,
  setRememberMe,
  setUserAlias,
}: LoginEmailBuilderHandlersType): LoginEmailBuilderReturnHandlersType => ({
  handleChangeRememberMeCheckBoxEvent: () =>
    changeRememberMeCheckBoxEvent({
      handleRememberMeChangeCheckboxTracking,
      rememberMe,
      setRememberMe,
    }),
  handleContinuePasswordGapsClick: () =>
    continuePasswordGapsClick({
      email,
      handleContinuePasswordGapsButtonClickTracking,
      setError,
      setFetching,
      setLoginView,
      setUserAlias,
    }),
  handleLoginRememberedUserEvent: () =>
    loginRememberedUserEvent({
      autoLogin,
      email,
      handleContinuePasswordGapsButtonClickTracking,
      handleEnterLoginEmailViewTracking,
      setAutoLogin,
      setError,
      setFetching,
      setLoginView,
      setUserAlias,
    }),
  handleSaveLoginEmailInputEvent: (newEmail: string) =>
    saveLoginEmailInputEvent({
      handleContinuePasswordGapsButtonClickTracking,
      newEmail,
      setEmail,
      setError,
      setFetching,
      setLoginView,
      setUserAlias,
    }),
  handleValidateLoginEmailInputEvent: () =>
    validateLoginEmailInputEvent({ email, handleEmailInputErrorStateTracking, setError }),
});

export default LoginEmailHandlers;
