// Vendors
import React from 'react';
// Components
import {
  ButtonComponent,
  CheckboxComponent,
  DividerComponent,
  FetchErrorComponent,
  InputTextComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import LoginLinksComponent from '../../components/links/login-links.component';
import LoginMessageComponent from '../../components/message/login-message.component';
// Constants
import constants from './constants/login-email.constants';
// Custom Hooks
import LoginEmailHook from './hooks/login-email.hook';
// Styles
import { LoginFormContentChildrenContainerStyled } from '../../login.container.styled';
import { LoginEmailInputMessageViewStyled, LoginEmailViewStyled } from './login-email.view.styled';
// Translations
import translations from './translations/login-email.translations';
// Utilities
import { continueLoginEmailButtonIsDisabled } from './utils/login-email.utils';

const LoginEmailView = (): React.ReactElement => {
  const {
    fetching: fetchingEmail,
    formatMessage,
    email,
    error,
    handleChangeRememberMeCheckBoxEvent,
    handleContinuePasswordGapsClick,
    handleEmailInputClickTracking,
    handleSaveLoginEmailInputEvent,
    handleValidateLoginEmailInputEvent,
    rememberMe,
  } = LoginEmailHook();

  return (
    <LoginEmailViewStyled>
      <FetchErrorComponent {...{ fetching: fetchingEmail }}>
        <TitleComponent {...constants.LOGIN_EMAIL_TITLE_PROPS}>
          <FormattedMessageComponent id={translations.TITLE} />
        </TitleComponent>
        <LoginFormContentChildrenContainerStyled>
          <LoginEmailInputMessageViewStyled>
            <InputTextComponent
              {...constants.LOGIN_EMAIL_INPUT_PROPS}
              errorMessage={error}
              disabled={fetchingEmail}
              label={formatMessage({ id: translations.INPUT_LABEL })}
              placeholder={formatMessage({ id: translations.PLACEHOLDER })}
              onBlur={handleValidateLoginEmailInputEvent}
              onChange={handleSaveLoginEmailInputEvent}
              onClick={handleEmailInputClickTracking}
              value={email}
            />
            <LoginMessageComponent {...{ ...constants.LOGIN_EMAIL_ERROR_PROPS, error }} />
          </LoginEmailInputMessageViewStyled>
          <CheckboxComponent
            {...constants.LOGIN_EMAIL_CHECKBOX_PROPS}
            disabled={fetchingEmail}
            onChange={handleChangeRememberMeCheckBoxEvent}
            value={rememberMe}
            title={formatMessage({ id: translations.CHECKBOX_LABEL })}
          />
          <ButtonComponent
            {...constants.LOGIN_EMAIL_BUTTON_PROPS}
            disabled={continueLoginEmailButtonIsDisabled({
              fetching: fetchingEmail,
              email,
              error,
            })}
            loading={fetchingEmail}
            onClick={handleContinuePasswordGapsClick}
          >
            <FormattedMessageComponent id={translations.BUTTON} />
          </ButtonComponent>
        </LoginFormContentChildrenContainerStyled>
        <DividerComponent {...constants.LOGIN_EMAIL_DIVIDER_PROPS} />
        <LoginLinksComponent {...{ ...constants.LOGIN_EMAIL_LINKS_PROPS }} />
      </FetchErrorComponent>
    </LoginEmailViewStyled>
  );
};

export default LoginEmailView;
