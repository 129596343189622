// Vendors
import React from 'react';
// Components
import {
  SectionDividerComponent,
  Notification,
  ErrorComponent,
  FetchErrorComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import MoreNotificationsComponent from '../more-notifications/more-notifications.component';
// Configurations
import { getFetchErrorLandingConfiguration } from './configurations/notifications-landing-content.configurations';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS,
  ERROR_NOT_FOUNT_PROPS,
} from '../../constants/notifications-landing.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  NotificactionsLandingViewContentStyled,
  NotificactionsLandingViewNotificationsStyled,
} from '../../notifications-landing.view.styled';
// Transalations
import translations from '../../translations/notifications-landing.translations';
// Types
import { NotificationsLandingContentPropsType } from './types/notifications-landing-content.component.props.type';
import { getFetchErrorConfiguration } from 'configurations/app.configurations';

const NotificationsLandingContentComponent = ({
  canGoNextPage,
  error,
  formValues,
  handleSetPagination,
  isLoading,
  isLoadingNextPage,
  notifications,
  onRetry,
}: NotificationsLandingContentPropsType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <FetchErrorComponent
      fetching={isLoading}
      error={getFetchErrorConfiguration(
        getFetchErrorLandingConfiguration({
          error,
          onClick: onRetry,
          formatMessage,
        })
      )}
    >
      {notifications.length === 0 ? (
        <ErrorComponent
          {...{ ...ERROR_NOT_FOUNT_PROPS }}
          title={<FormattedMessageComponent id={translations.NOT_FOUND_TITLE} />}
          description={
            <FormattedMessageComponent
              id={
                translations[
                  Object.keys(formValues).length > 0
                    ? 'NOT_FOUND_BY_FILTERS'
                    : 'NOT_FOUND_DESCRIPTION'
                ]
              }
            />
          }
        />
      ) : (
        <NotificactionsLandingViewContentStyled>
          <SectionDividerComponent
            {...CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS}
            title={<FormattedMessageComponent id={translations.READED_TITLE} />}
          />
          <NotificactionsLandingViewNotificationsStyled>
            {notifications.map(notification => (
              <Notification
                {...notification}
                key={notification.messageId}
                isReaded={notification.isReaded}
              />
            ))}
          </NotificactionsLandingViewNotificationsStyled>

          {canGoNextPage && (
            <MoreNotificationsComponent
              isLoading={isLoadingNextPage}
              onNext={handleSetPagination}
            />
          )}
        </NotificactionsLandingViewContentStyled>
      )}
    </FetchErrorComponent>
  );
};

export default NotificationsLandingContentComponent;
