// Vendors
import { cookies } from '@openbank/cf-ui-architecture-core';
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// Contexts
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Event handlers
import { OrchestratorHandlers } from '../handlers/orchestrator.handlers';
// Hooks
import usePrevLocation from 'hooks/use-prev-location.hook';
import AppTrackingCustomHook from 'tracking/hooks/app.tracking.custom.hook';
// Types
import { OrchestratorHookType } from './types/orchestrator.hook.type';
// Utils
import { isOnboardingIncompleted } from 'utils/onboarding.utils';

const useOrchestratorHook = (): OrchestratorHookType => {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const onboardingCompleted = cookies.getCookie(StorageCookiesEnum.ONBOARDING_COMPLETED);

  const { fetching, pageLoader, setError } = ManagerContextConsumerHook(),
    { notchSize, onHybridFlow } = MobileConnectorContextConsumerHook();

  const {
    handleChangePrevPathLocation,
    handleOnboardingRedirect,
    handleResetPageLoaderErrorEvent,
    handleUpdateHybridFlowsEvents,
    handleWatchInteractionsPublicSiteFlags,
  } = OrchestratorHandlers({
    pathName: prevLocation.pathname,
    navigate: useNavigate(),
    onHybridFlow,
    setError,
  });

  AppTrackingCustomHook();
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isOnboardingIncompleted(onboardingCompleted)) {
      handleOnboardingRedirect();
    }
  }, []);

  useEffect(() => {
    handleWatchInteractionsPublicSiteFlags();
  }, []);

  useEffect(() => {
    handleResetPageLoaderErrorEvent();
    handleUpdateHybridFlowsEvents();
    handleChangePrevPathLocation();
  }, [location.pathname]);

  return {
    fetching,
    notchSize,
    pageLoader,
  };
};

export { useOrchestratorHook };
