// Enumerations
import { LoginViewsEnumeration } from 'containers/login/enumerations/login-views.enumeration';
// Types
import { LoginErrorBuilderReturnHandlersType } from './types/login-error-builder-return.handlers.type';
import { LoginErrorBuilderHandlersType } from './types/login-error-builder.handlers.type';

const retryAgainButtonClickHandler = ({
  handleRetryAgainButtonClickTracking,
  setLoginView,
}: LoginErrorBuilderHandlersType): any => {
  handleRetryAgainButtonClickTracking();
  setLoginView(LoginViewsEnumeration.EMAIL);
};

const LoginErrorHandlers = (
  props: LoginErrorBuilderHandlersType
): LoginErrorBuilderReturnHandlersType => ({
  handleRetryAgainButtonClick: () => retryAgainButtonClickHandler(props),
});

export default LoginErrorHandlers;
