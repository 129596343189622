// Constants
import { buttonConstants, DividerTypesEnumeration } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/login-gaps.translations';
// Utils
import { getVerifyAccountUrl } from 'containers/login/utils/login.utils';

export default {
  LOGIN_GAPS_LINKS_PROPS: {
    links: [
      {
        href: 'https://www.amazon.de/dp/B0CN8XJB15?ref=AVOBWEB&plattr=AVOBWEB',
        isExternal: true,
        testId: 'login-gaps-links-1',
        text: translations.REQUEST_ACCESS,
      },
      {
        href: getVerifyAccountUrl(),
        isExternal: true,
        testId: 'login-gaps-links-2',
        text: translations.VERIFY_ACCOUNT,
      },
    ],
  },
  LOGIN_GAPS_ERROR_MESSAGE: {
    message: translations.ERROR_INPUT,
  },
  LOGIN_GAPS_LINK_PROPS: {
    testId: 'login-gaps-links',
  },
  LOGIN_GAPS_INPUT_PROPS: {
    cellsAmount: 4,
    testId: 'login-gaps-dropdown',
  },
  LOGIN_EMAIL_BUTTON_PROPS: {
    size: buttonConstants.SIZES.FULL,
    testId: 'login-gaps-button',
    type: buttonConstants.TYPES.PRIMARY,
  },
  LOGIN_EMAIL_DIVIDER_PROPS: {
    testId: 'login-gaps-divider',
    type: DividerTypesEnumeration.TERTIARY_D,
  },
};
